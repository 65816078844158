import styled, { css } from "styled-components";
import media from "styled-media-query";
import * as TypographStyles from "components/Typography";

// import bernardo from "assets/images/whatWeDoSlider/bernardo.jpg";
// import bernardo from "assets/images/whatWeDoSlider/bernardo.jpeg";
import bernardo from "assets/images/whatWeDoSlider/bernardov2.jpg";



export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: 620px;
    display: grid;
    grid-template-columns: minmax(320px, 510px) 1fr;
    grid-gap: ${theme.spacings.xxlarge};
    margin: ${theme.spacings.xlarge} auto;
    margin-left: 0;
    max-width: 1300px;
    padding: 0 ${theme.spacings.small} 0 0;

    ${media.greaterThan("huge")`
      margin-left: auto;
    `}

    ${media.lessThan("large")`
      grid-gap: ${theme.spacings.large};
    `}

    ${media.lessThan("medium")`
    height: 100%;
      grid-gap: ${theme.spacings.medium};
      grid-template-columns: 1fr;
      margin: 0 auto;
      padding: 0;
    `}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    height: 100%;
    width: 100%;
    > h2 {
      font-size: 32px;
    }

    h4 {
      font-size: 16px;
    }

    /* ${media.lessThan("476px")`
    height: 450px;
    padding-bottom: 20px;
    
    `} */
    ${media.lessThan("1367px")`
    padding: 0 ${theme.spacings.small};
    

    ${TypographStyles.Title} {
      font-size: ${theme.font.sizes.xxlarge};

      &:not(:last-child) {
        margin-bottom: ${theme.spacings.small};

      }
    }

    ${TypographStyles.Paragraph} {
      margin-bottom: ${theme.spacings.small};;
    }
    `}

    ${media.lessThan("medium")`
      padding: 0 ${theme.spacings.small};

      ${TypographStyles.Title} {
        font-size: ${theme.font.sizes.large};

        &:not(:last-child) {
          margin-bottom: ${theme.spacings.small};
        }
      }
    `}
  `}
`;
export const Slider = styled.div`
  background-size: contain;
  height: 520px;
  width: 100%;
  background-image: url(${bernardo});
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;

  ${media.lessThan("1367px")`
  height: 520px;
  `}

  ${media.lessThan("medium")`
    height: 510px;
  `}

  canvas {
    ${media.lessThan("1367px")`
      height: calc(100vh - 100px) !important;
      width: unset !important;
    `}

    ${media.lessThan("medium")`
      height: 80% !important;
      width: 100% !important;
    `}
  }
`;

export const CollapseGroup = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xlarge};
    width: 100%;

    ${media.lessThan("1367px")`
      margin-top: ${theme.spacings.small};
    `}

    ${media.lessThan("medium")`
      margin-top: ${theme.spacings.small};
    `}
  `}
`;
